const instancesPool:{ [key: string]: any } = {
}

const lazyInstancesFactory: { [key: string]: any } = {
 
    // all instance(ex: auth, device, database...ect) should be gotten via App.
    // because if you try to get them separately (such as using DI.get('Auth') to get auth), you will get a auth instance which not be initialized.
    // and this will result in fail when you try to invoke methods of auth or device.
    // for more detail, you can read App.js and ComponentRegister.js in sdk to understand how App is built.
    
    
   
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    App:() => require('fdtigermaster-client-sdk/lib/src/index').default, 
}

export const get = (name: string):any => {
    if (name in instancesPool) {
        return instancesPool[name];
    }

    if (!(name in lazyInstancesFactory)) {
        throw new Error('Instance not set');
    }
    const instance = lazyInstancesFactory[name]();
    instancesPool[name] = instance;
    return instance;
}

export const set = <T>(name: string, instance: any): T => {
    instancesPool[name] = instance;
    return instance;
}
