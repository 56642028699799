import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

declare module 'vue-router' {
    interface RouteMeta {
      // below must be declared by every route
      
      // below is optional - does not have to be on every route
      breadcrumb?: { name?: string; link?: string }[]
    }
  }

Vue.use(VueRouter)

const Home: any = () => import('@/views/Home.vue')
const About: any = () => import('@/views/About.vue')
const QA: any = () => import('@/views/QA.vue')
const Quotation: any = () => import('@/views/Quotation.vue')
const ClockIn: any = () => import('@/views/ClockIn.vue')
const QAPage: any = () => import('@/components/QA/QApage.vue')
const QAAccount: any = () => import('@/components/QA/Account.vue')
const QAOrder: any = () => import('@/components/QA/Order.vue')
const Service: any = () => import('@/views/Service.vue')
const Law: any = () => import('@/views/Law.vue')
const MasterManagement: any = () => import('@/views/MasterManagement.vue')
const Privacy: any = () => import('@/views/Privacy.vue')
const PaymentLoading: any = () => import('@/views/PaymentLoading.vue')
const ResetPassword: any = () => import('@/views/ResetPassword.vue')
const MobileQA: any = () => import('@/views/MobileQA.vue')
const JoinUs: any = () => import('@/views/JoinUs.vue')
const TigerSchool: any = () => import('@/views/Academy.vue')

// Academy do not start yet. The routers should be closed.
const Academy: any = () => import('@/views/Academy.vue')
const AcademyPage: any = () => import('@/components/Academy/AcademyPage.vue')
const Course: any = () => import('@/components/Academy/Course.vue')
const CourseInfo: any = () => import('@/components/Academy/CourseInfo.vue')
const CourseQA: any = () => import('@/components/Academy/CourseQA.vue')
const AcademyQA: any = () => import('@/components/QA/AcademyQA.vue')
const CourseTimetable: any = () => import('@/components/Academy/Utility/CourseTimetable.vue')
const EnquireCards: any = () => import('@/components/Academy/Utility/EnquireCards.vue')
const CourseSignUp: any = () => import('@/components/Academy/CourseSignUp/CourseSignUp.vue')
const TigerLocation: any = () => import('@/components/Academy/TigerLocation.vue')
const AcademyAD: any = () => import('@/components/Academy/AcademyAD.vue')
const EnquireDispatch: any = () => import('@/components/Academy/EnquireDispatch.vue')
const EnquireTraining: any = () => import('@/components/Academy/EnquireTraining.vue')
const AcademyParticipant: any = () => import('@/components/Academy/AcademyParticipant/AcademyParticipant.vue')
const EnterpriseEnquire: any = () => import('@/components/Academy/EnterpriseEnquire/EnterpriseEnquire.vue')
const CreateDispatch: any = () => import('@/components/Academy/CreateEnquireDispatch/CreateDispatch.vue')
const AcademyLaw: any = () => import('@/views/AcademyLaw.vue')
// Academy do not start yet. The routers should be closed.

const ServiceItem: any = () => import('@/views/ServiceItem.vue')
const SignUp: any = () => import('@/views/SignUp.vue')
const CustomPayment: any = () => import('@/views/CustomPayment.vue')
const News: any = () => import('@/views/News.vue')
const NotFound: any = () => import('@/views/NotFound.vue')

const QuotationList :any = () => import('@/views/Quotation/QuotationList.vue')
const QuotationInfo :any = () => import('@/views/Quotation/QuotationInfo.vue')
const QuotationStandard :any = () => import('@/views/Quotation/QuotationStandard.vue')
const QuotationPdf :any = () => import('@/views/Quotation/QuotationPdf.vue')

const ClockLogin :any = () => import('@/views/Clock/ClockLogin.vue')
const ClockInPage :any = () => import('@/views/Clock/ClockInPage.vue')

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/About',
        name: 'About',
        component: About
    },
    {
        path: '/',
        name: 'QA',
        component: QA,
        children: [
            {
                path: '/QA',
                component: QAPage,
                meta: {
                    breadcrumb: [
                        { name: '常見問題' },
                        { name: '❯' }
                    ]
                }
            },
            {
                path: '/QA/Account',
                component: QAAccount,
                meta: {
                    breadcrumb: [
                        { name: ' 常見問題 ', link: '/QA' },
                        { name: ' ❯ ', link: '/QA' },
                        { name: '帳號相關問題' }
                    ]
                }
            },
            {
                path: '/QA/Order',
                component: QAOrder,
                meta: {
                    breadcrumb: [
                        { name: '常見問題 ', link: '/QA' },
                        { name: ' ❯ ', link: '/QA' },
                        { name: '下訂相關問題' }
                    ]
                }
            },
            {
                path: '/QA/AcademyQA',
                component: AcademyQA,
                meta: {
                    breadcrumb: [
                        { name: '常見問題 ', link: '/QA' },
                        { name: ' ❯ ', link: '/QA' },
                        { name: '下訂相關問題' }
                    ]
                }
            },
        ]
    },
    {
        path: '/Service',
        name: 'Service',
        component: Service
    },
    {
        path: '/Law',
        name: 'Law',
        component: Law
    },
    {
        path: '/MasterManagement',
        name: 'MasterManagement',
        component: MasterManagement
    },
    {
        path: '/Privacy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/PaymentLoading',
        name: 'PaymentLoading',
        component: PaymentLoading
    },
    {
        path: '/ResetPassword',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/MobileQA',
        name: 'MobileQA',
        component: MobileQA
    },
    {
        path: '/JoinUs',
        name: 'JoinUs',
        component: JoinUs
    },
    {
        path: '/TigerSchool',
        name: 'TigerSchool',
        component: TigerSchool
    },

    // Academy do not start yet. The routers should be closed.
    {
        path: '/Academy',
        name: 'Academy',
        component: Academy,
        children: [
            {
                path: '/Academy',
                component: AcademyPage
            },
            {
                path: 'Course',
                component: Course
            },
            {
                path: 'CourseInfo',
                component: CourseInfo
            },
            {
                path: 'CourseQA',
                component: CourseQA
            },
            {
                path: 'CourseTimetable',
                component: CourseTimetable,
                props: true
            },
            {
                path: 'EnquireCards',
                component: EnquireCards
            },
            {
                path: 'CourseSignUp',
                component: CourseSignUp
            },
            {
                path: 'AcademyAD',
                component: AcademyAD,
                props: true
            },
            {
                path: 'TigerLocation',
                component: TigerLocation
            },
            {
                path: 'EnquireDispatch',
                component: EnquireDispatch
            },
            {
                path: 'EnquireTraining',
                component: EnquireTraining
            },
            {
                path: 'AcademyParticipant',
                component: AcademyParticipant
            },
            {
                path: 'EnterpriseEnquire',
                component: EnterpriseEnquire
            },
            {
                path: 'CreateDispatch',
                component: CreateDispatch
            },
            {
                path: 'AcademyLaw',
                component: AcademyLaw
            }
        ]
    },
    // Academy do not start yet. The routers should be closed.

    {
        path: '/ServiceItem',
        name: 'ServiceItem',
        component: ServiceItem
    },
    {
        path: '/SignUp',
        name: 'SignUp',
        component: SignUp
    },
    {
        path: '/CustomPayment',
        name: 'CustomPayment',
        component: CustomPayment
    },
    {
        path: '/News',
        name: 'News',
        component: News
    },
    {
        path: '/',
        name: 'Quotation',
        component: Quotation,
        children: [
            {
                path: '/Quotation/List',
                component: QuotationList
            },
            {
                path: '/Quotation/Standard',
                component: QuotationStandard
            },
            {
                path: '/Quotation/Info',
                component: QuotationInfo
            },
            {
                path: '/Quotation/Pdf',
                component: QuotationPdf
            },
        ]
    },
    {
        path: '/',
        name: 'ClockIn',
        component: ClockIn,
        children: [
            {
                path: '/ClockIn/Login',
                component: ClockLogin
            },
            {
                path: '/ClockIn/ClockInPage',
                component: ClockInPage
            }
        ]
    },
    {
        path: '*',
        component: NotFound
    }
]

const router = new VueRouter({
    scrollBehavior() {
        return { x: 0, y: 0, behavior: 'smooth' };
    },
    mode: 'history',
    routes
})

export default router
