import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/style/reset.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/style/common.css';
import { BootstrapVue, BootstrapVueIcons,CollapsePlugin } from 'bootstrap-vue';
import { IconPack, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import 'font-awesome/css/font-awesome.css';
import VueGtag from 'vue-gtag'
import VueAwesomeSwiper from 'vue-awesome-swiper'

library.add(fas as IconPack);
library.add(fab as IconPack);
library.add(far as IconPack);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(CollapsePlugin);
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.use(VueGtag, {
    config: {
        id: 'G-98G6TKQ5SW'  // Google Analytics Tracking ID
    }
},router);
// eslint-disable-next-line
// @ts-ignore
import VueFacebookPixel from '@blaaat/vue-facebook-pixel'
Vue.use(VueFacebookPixel);
// eslint-disable-next-line
// @ts-ignore
Vue.analytics.fbq.init('193356378700303');
new Vue({
  router,
  store,
  render: h => h(App),
   mounted() {
        document.dispatchEvent(new Event('render-event'));
    },
}).$mount('#app')



