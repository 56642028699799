
import { Component, Vue } from "vue-property-decorator";
import QuotationLogin from "@/components/Quotation/QuotationLogin.vue";
import * as DI from "@/utility/DependencyInjector";
 @Component({
        components:{
            QuotationLogin,
        }
    })
export default class Footer extends Vue {
  //data
   isLogin = DI.get("App").auth.currentUser;
  list = [
    { name: "安裝服務", index: 0 },
    { name: "水電服務", index: 1 },
    { name: "清潔服務", index: 2 },
    { name: "裝潢整修", index: 3 },
    { name: "搬運服務", index: 4 },
    { name: "家電服務", index: 5 },
    { name: "房屋漏水", index: 6 },
    { name: "消毒病媒", index: 7 },
  ];
  //methods
   showModal(name:string):void{
      this.isLogin = DI.get("App").auth.currentUser;
      if(this.isLogin){

        if (this.$route.path !== '/Quotation/List') {
    this.$router.push({ path: '/Quotation/List' });

  }
     window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      }else{
        this.$bvModal.show(name);
      }
        }
  //computed
  get year(): string {
    return " " + new Date().getFullYear();
  }
}
