
import { Component, Vue } from "vue-property-decorator";
import tigermaster from "fdtigermaster-client-sdk";

import Navbar from "@/components/Utility/Navbar.vue";
// import NavbarWithAcademyDropdown from '@/components/Utility/NavbarWithAcademyDropdown.vue'
import Footer from "@/components/Utility/Footer.vue";

@Component({
  components: {
    Navbar,
    // NavbarWithAcademyDropdown,
    Footer,
  }
})
export default class App extends Vue {
  // data
  isLoading = true;

  async created():Promise<void> {
    await tigermaster.initializeApp({
      stage: process.env.VUE_APP_BACKEND_URL,
    });
    this.isLoading = false;
  }
}
