import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:{}, 
    bookmark: [], 
    isRefresh:false,
    isLogin:false,
    address:{},
    quotation:[],   
  },
  mutations: {
    setUser(state, data){
      localStorage.setItem("user", JSON.stringify(data));
      state.user = data;
    },
    clearUser(state) {
      state.user = {};
    },   
    setBookmark(state, bookmark) {
      state.bookmark = bookmark;
    },
    refreshPage(state){
      state.isRefresh = !state.isRefresh;
    },
    LoginState(state, data){
      state.isLogin = data;
    },
    setAddress(state, data) {
      state.address = data;
    },
    setQuotation(state,data){
      state.quotation = data;
    },    
  
  },
  actions: {
  },
  modules: {
  }
})
